// english messages

export default {
    short: "I'm a Machine Learning Team Lead at Raft. I spend \
    my days (apart from working) \
    reading, playing racket sports (tennis, table tennis, and badminton), \
    and scrolling through YouTube. I'm currently based in London, UK.",
    long: [
        "Hello! Call me V \
        and I'm the Forward Deployed Machine Learning Engineer at Vector.ai & \
        . Before this, I worked as a \
        Software Engineer at a stealth startup for about a year and a half.\
        While studying CS, I also worked at Orai as an AI intern for nearly two years.",
        "I don't have specific goals in life, but I believe in having processes \
        and systems. One of my systems is to keep learning. Apart from this, \
        I like to keep myself fit and to eat healthily. I'm an engineer at heart \
        and love building things. I'm also equally interested in \
        economics and well pretty much anything. \
        If you'd like to talk more about such things or want \
        help with CS, research papers, life optimization- feel free to hit me up.",
        "My grandfather taught me HTML when I was 7, but I didn't pick up \
        programming until I was 12. I published my \
        first game for iOS when I was 15. After that, I dived into AI. As a \
        kid, I disliked languages, history, geography, and any non-STEM \
        subjects, but with time I learned that these fields have a special place \
        that I didn't know how to value before. I, now, try to catch up \
        with all that I've ignored as a child."
    ],
    speaker: 'Vidur is a Software Engineer.\
    He\'s a recent graduate and previously interned at Orai as an AI Intern \
    . His favorite quote is from Steve Jobs: \
    "Stay Hungry. Stay Foolish" \
    He loves overwhelming himself by learning new things.',
    advice: {
        h1: "Advice on contacting me",
        h1l: [
            "Communication is hard. People have different \
            expectations on how others should communicate, and \
            these different expectations cause misunderstandings \
            and occasionally ruin relationships.",
            "Yesterday, a friend got mad at me because he \
            thought we made a plan; and I canceled it, while I \
            thought we only agreed that we’d hang out but didn’t \
            make any plans. Often, people mistake my delay in \
            response for rudeness or \
            for lack of interest.",
            "To avoid further unnecessary headaches, \
            I try to make clear how I communicate."
        ],

        h3: "Email",
        h3b: "If we’ve never met before, the best way \
        to reach me is via email. I check \
        my inbox somewhat frequently and try to respond to \
        every email. If I don’t respond to your email, it \
        might be because of the following reasons:",
        h3l: [
            "Your email is long. Please keep your first email \
            to under 1-minute read. I find 20-200 words to be the ideal length.",
            "It must've gotten lost in my inbox."
        ],

        h4: "Scheduling",
        h4l: [
            "I have a full-time job, and when not working, I do side \
            projects (see Goodreads, open-source projects). \
            I have little free time.",
            "I have a short-term memory. So, I might forget things \
            that aren’t on my calendar. Please send me a calendar \
            invite if I haven’t sent you one already. AND don't mind \
            if I send you an invite or my calendly link."
        ],

        h5: "Conclusion",
        h5b: "When it comes to communication, I’m systematic \
        but not inflexible. This post is a reference to avoid \
        misunderstandings, not a rule that you can’t break. \
        Some of the best memories I’ve had are from spontaneous \
        decisions. I’d love to have more free spirits \
        and rulebreakers in my life."
    }
};