<template>
  <div class="container">
    <div class="row">
      <img :src="profilePath" alt="Portrait picture of Vidur Satija" class="img-frame" />
      <div class="second">
        <div class="header-section">
          <h1>Vidur Satija</h1>
        </div>
        <br />
        <div class="bio-switcher">
          <div class="bio short">
            {{ $t('short') }}
          </div>
        </div>
        <div class="links">
          <a href="http://github.com/vidursatija">GitHub</a>
          <a href="http://linkedin.com/in/vidursatija">LinkedIn</a>
          <a href="mailto:applevidur@gmail.com">Email</a>
          <a href="https://goodreads.com/viduraaaaa">Goodreads</a>
          <!-- <a href="https://discordapp.com/users/judastrivia#8237">Discord</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      profilePath: require("@/assets/Vidur2023.webp"),
    }
  },
}
</script>

<style>
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}

body,
button {
  background: #000;
  color: #fff;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.5;
  border-color: #777777;
}

::selection {
  background-color: #48aaeb;
  color: #000;
}

.second {
  padding: 60px;
  position: inherit;
}

button:hover {
  background: rgba(150, 151, 156, 0.1);
  cursor: pointer;
}

.container {
  position: absolute;
  width: 100vw;
  height: 100vh;
}


.header-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
}


h1 {
  font-weight: 600;
  margin: 0;
}

a {
  color: #96979c;
  text-decoration: none;
  font-size: 18px;
}

a:hover {
  color: #fff;
}

.img-frame {
  width: 100vw;
  height: auto;
  top: 0%;
  overflow: hidden;
  left: 0px;
  display: block;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.9) 80%, rgba(0, 0, 0, 0) 100%);
}

.links {
  margin: 25px 0;
  display: flex;
  align-items: left;
  justify-content: left;
  flex-wrap: wrap;
}

.links a:not(:last-child) {
  padding: 0 16px 0 0;
}

.bio {
  line-height: 24px;
  font-size: 20px;
  text-align: justify;
  font-weight: 200;
}

.bio br {
  display: block;
  content: "";
  height: 15px;
}

@media screen and (min-width: 600px) and (orientation: landscape) {
  .img-frame {
    bottom: 0%;
    height: 100vh;
    overflow: hidden;
    left: 0px;
    display: block;
    width: auto;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.9) 60%, rgba(0, 0, 0, 0) 100%);
  }

  .second {
    padding: 60px;
    position: absolute;
    transform: translateY(-50%);
    right: 0;
    top: 50vh;
    max-width: 45vw;
  }
}
</style>
