import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import en from './messages/en'

const messages = {
    en,
};

const i18n = createI18n({
    locale: 'en', // set locale
    messages, // set locale messages
    interpolation: {escapeValue: false}
});

// Vue.config.productionTip = false;

let app = createApp(App);
app.use(i18n);
app.mount('#app');
